angular.module('tgaApp.services.user-service', [])
.service('userService',
function (
    $http,
    authService,
    dataService,
    envService
) {

    this.serverAddress = envService.read('serverAddress');
    this.storageKey = 'player';
    this.user = null;

    this.getUser = function(callback, game_id){
        var service = this;
        var url = service.serverAddress + 'player';
        $http({
            method: 'GET',
            url: game_id ? url + '?game_id='+game_id:url
        }).then(
            function (response) {
                service.setUser(response.data);
                if(callback){
                    callback(service.user);
                }
            },
            function (error) {
                if(callback){
                    callback(error);
                }
            }
        );
    };

    this.getUserData = function(checkAuth){
        if (!this.user) {
            this.user = dataService.getData(this.storageKey);
        }
        if(checkAuth && !authService.getAuthentication().isAuth) {
            return false;
        }
        return this.user;
    };

    this.setUser = function(newUser){
        this.user = newUser;
        dataService.setData(this.storageKey, this.user, true);
    };

    this.updateUser = function(data, callback){
        var service = this;
        $http({
            method: 'PUT',
            url: service.serverAddress + 'player',
            data: data
        }).then(
            function (response) {
                service.setUser(response.data);
                if(callback){
                    callback(service.user);
                }
            },
            function (error) {
                if(callback){
                    callback(error);
                }
            }
        );
    };

    this.register = function(data, callback) {
        var authentication = authService.clearAuthentication();
        var service = this;
        if(!data) {
            data = {
                username: this._randomString(),
            };
        }

        $http({
            method: 'POST',
            url: service.serverAddress + 'player/register',
            headers: { 'Content-Type': 'application/json' },
            data: data
        }).then(
            function (response) {
                authentication.isAuth = true;
                authentication.username = data.username;
                authentication.token = response.data.key;
                authentication.userId = response.data.id;

                authService.setAuthentication(authentication, true);
                if(callback){
                    service.getUser(callback);
                }

            },
            function (error) {
                if(!service.serverAddress) {
                    service.setUser(true);
                    if(callback){
                        callback(true);
                    }
                }else{
                    service.logout();
                    if(callback){
                        callback(error);
                    }
                }
            }
        );
    };

    this.logout = function() {
        authService.clearAuthentication();
        this.setUser(null);
    };

    this.startSession = function(data, callback) {
        var service = this;
        $http({
            method: 'POST',
            url: service.serverAddress + 'player/session',
            data: data
        }).then(
            function (response) {
                if(callback){
                    callback(response);
                }
            },
            function (error) {
                if(callback){
                    callback(error);
                }
            }
        );
    };

    this._randomString = function(length) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        length = length || 10;
        var string = '', rnd;
        while (length > 0) {
            rnd = Math.floor(Math.random() * chars.length);
            string += chars.charAt(rnd);
            length--;
        }
        return string;
    };

});