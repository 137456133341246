app.directive('desicionSlider', function($rootScope) {
  var controller = [
  '$scope',
  '$timeout',
  function(
    $scope,
    $timeout
    ){

      $scope.init = function(){
        $scope.questionAnswered = $scope.noEvent = $scope.showEvent = $scope.noFeedback = $scope.showFeedback = false;
        $scope.index = $scope.$parent.$index;
        $scope.answeredPoints = 0;
        $scope.inputAnswer = undefined;

        $scope.answers = $scope.$parent.card.answers;

        var sArray = [];

        $scope.answers.forEach(function(a, i){
          sArray.push({
            value:i,
            legend:a.text
          });
        }.bind(this));

        $scope.gameSlider = {
          value:0,
          options:{
            // floor:0,
            // ceil:$scope.answers.length-1,
            scale: 1,
            showTicksValues: true,
            showticks:true,
            stepsArray:sArray
          }
        };

        $timeout(function () {
          $scope.$emit('check-ratio');
          $scope.$broadcast('rzSliderForceRender');
          $scope.selectTick(0);
        });

        $scope.checkPreEvent();

      };
      $scope.feedbackComplete = function(card, index){
        if($scope.checkPassRequiredFailed(card)){
          $scope.init();
        }else if(card.event){
          $scope.showFeedback = false;
          $scope.showEvent = true;
          $scope.event = card.event;
        }else {
          $scope.buttonPressed(card.navigation);
        }
      };

      $scope.checkEventOrFeedback = function(){
        var card = $scope.$parent.card;
        if(card.event || card.feedback){
          return false;
        }
        var answerNoFeedback = false;
        card.answers.forEach(function(a){
          if(a.feedback === undefined){
            answerNoFeedback = true;
          }
        }.bind(this));

        if(answerNoFeedback){
          return true;
        }else{
          return false;
        }
      };

      $scope.submitAnswer = function(card){
        var answer = $scope.answers[$scope.gameSlider.value];
        $scope.isCorrect = (card.correct <= answer.points);

        $scope.questionAnswered = true;
        var points = answer.points;
        $scope.answeredPoints = points;
        var question_time = Date.now() - $scope.qTimer;
        var passRequiredFailed = $scope.checkPassRequiredFailed(card);
        var answerSubmission = {
          card_id:$scope.index,
          type:'slider',
          question_time:question_time,
          question_id:card.question_id,
          correct:$scope.isCorrect,
          answer_id:$scope.gameSlider.value,
          answer_text:$scope.answers[$scope.gameSlider.value].text,
          answer:answer,
          no_bounce:card.no_bounce?true:false,
          points: card.points_override !== undefined ? card.points_override:$scope.answeredPoints
        };
        if(!passRequiredFailed){
          $scope.$emit('submit-answer', answerSubmission);
        }


        if(card.feedback || answer.feedback){
          $timeout(function(){
            $scope.showFeedback = true;
          });
          $scope.feedback = $scope.isCorrect ? card.feedback.correct : card.feedback.incorrect;
        }else if($scope.checkPassRequiredFailed(card)){
          $scope.init();
        }else if(card.event){
          $scope.showEvent = true;
        }else{
          $scope.noFeedback = $scope.noEvent = true;
        }
      };

      $scope.checkPassRequiredFailed = function(card){
        if(card.required_pass !== true){
          return false;
        }else{
          return !$scope.isCorrect;
        }
      };

      $scope.checkPreEvent = function(){
        $scope.showPreEvent = $scope.parent.card.pre_event;
      };

      $scope.checkPreEvent = function(){
        $scope.showPreEvent = $scope.$parent.card.pre_event?true:false;
      };

      $scope.buttonPressed = function(navigation){
        // console.log('desicion-card button pressed-> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };

      $scope.startTimer = function(){
        $scope.qTimer = Date.now();
      };

      $scope.$on('init', function (event, data) {
        if(data.index === $scope.index){
          $scope.init();
        }
      });

      $scope.selectTick = function(index){
        $('.card-'+$scope.index+' .rz-ticks .rz-tick').removeClass('user-selected');
        $('.card-'+$scope.index+' .rz-ticks .rz-tick').eq(index).addClass('user-selected');
      };

      $scope.$on("slideEnded", function(event) {
        if(event.targetScope.$id === $scope.$$childHead.$id){
          $scope.selectTick($scope.gameSlider.value);
        }
      });

      $scope.$on('resize-scale', function (event, data) {
        $scope.gameSlider.options.scale = 1/data.scale;
        $scope.$broadcast('rzSliderForceRender');
      });

      $scope.$on('slide-change-start', function (event, data) {
        $scope.$broadcast('rzSliderForceRender');
      });

      $scope.$on('slide-change-end', function (event, data) {
        $scope.$broadcast('rzSliderForceRender');
        if(data.index === $scope.index){
          $scope.startTimer();
        }
      });

      $scope.init();

  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/desicions/desicion-slider.html',
      link: function(scope, elem, attrs) {
      },
      controller:controller
  };
});