angular.module('tgaApp.services.phaser-game-service', [])
.service('phaserGameService', function (
    $http
) {
    this.game = null;

    this.destroyPhaserGame = function(){
        if(this.game !== null){
            // console.log(this.game);
            this.game.destroy();
        }
    };

    // load a phaser game with states
    // settings arguments
    // width: (int) width of game canvas
    // height: (int) height of game canvas
    // id: (string) id attr of canvas element
    // states: array ({name:(string), url:(string)}) array of states objects with url string for file and name
    // start: (string) name of state to start the game with
    // onComplete: (function) function to run when game is loaded prior to start
    this.loadPhaserGame = function(settings){
        console.log('loading phaser game');
        var service = this;
        var game = new Phaser.Game(settings.width, settings.height, settings.render || Phaser.Auto, settings.id);
        var loadCounter = settings.states.length;

        this.destroyPhaserGame();

        settings.states.forEach(function(s){
            $http({
                method:'GET',
                url:s.url
            }).then(
                function success(response) {
                    var state = eval(response.data);
                    game.state.add(s.name, state);
                    loadCounter--;
                    if(loadCounter === 0){
                        if(settings.onComplete) settings.onComplete(game);
                        game.state.start(settings.start);
                        service.game = game;
                    }
                },
                function error(response) {
                    console.log(response);
                }
            );
        });
    };

    this.getGame = function(){
        return this.game;
    };

});