angular.module('tgaApp.controllers.progress', [])
.controller('ProgressCtrl', [
    '$rootScope',
    '$scope',
    '$compile',
    '$timeout',
    'soundService',
    'loadingSpinnerService',
    'activityDataService',
    'dataService',
    'videoService',
    'cssAnimationService',
    'envService',
    'analyticsService',
    'scoreService',
    'userService',
    'scormService',
    'gameConfig',
    '$stateParams',
    '$state',
    'utilityService',
    function(
        $rootScope,
        $scope,
        $compile,
        $timeout,
        soundService,
        loadingSpinnerService,
        activityDataService,
        dataService,
        videoService,
        cssAnimationService,
        envService,
        analyticsService,
        scoreService,
        userService,
        scormService,
        gameConfig,
        $stateParams,
        $state,
        utilityService
    ) {
    console.log('controllers/progress.js up');
    $scope.progress_prefix = 'Earnings: $';
    $scope.progress_postfix = '';

    $scope.scoreData = [
        // {
        //     id:'finance',
        //     title:"Finance",
        //     min_value:0,
        //     max_value:100,
        //     points:50
        // },
        // {
        //     id:'consolidation',
        //     title:"Consolidation",
        //     min_value:0,
        //     max_value:100,
        //     points:25
        // },
        // {
        //     id:'collocation',
        //     title:"Collocation",
        //     min_value:0,
        //     max_value:100,
        //     points:66
        // },
        // {
        //     id:'powercut',
        //     title:"PowerCut",
        //     min_value:0,
        //     max_value:100,
        //     points:100
        // },
        // {
        //     id:'retrofit',
        //     title:"Retrofit",
        //     min_value:0,
        //     max_value:100,
        //     points:45
        // }
    ];

    $scope.getPrefix = function(score){
        var id = score.id;
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            if(scenarios[i].id === id){
                return scenarios[i].points_prefix;
            }
        }
        return 'Earnings $:';
    };

    $scope.getCurrency = function(score){
        var id = score.id;
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            if(scenarios[i].id === id){
                return scenarios[i].currency;
            }
        }
        return '$';
    };

    $scope.getProgressPercentage = function(score){
        // console.log(score);
        var val = Math.round(utilityService.convertRange(score.points, [score.min_value, score.max_value], [0, 100]));
        if(val < 0) val = 0;
        if(val > 100) val = 100;
        return utilityService.convertRange(score.points, [score.min_value, score.max_value], [0, 100]);
    };

    $scope.gotoSelect = function(game){
        $state.go('select');
    };

    $scope.checkFilterScores = function(){
        if(!$scope.playerData || !$scope.scenarioData){return;}
        $scope.scoreData = $scope.filterScores($scope.playerData);
    };

    $scope.filterScores = function(data){
        if(data.completed)return $scope.playerData.scenarioScores;
        var scoreArray = [];
        var currentPosition = $scope.getScenarioPos($scope.playerData.current_scenario);
        var scenarioScores = $scope.playerData.scenarioScores;

        for (var i = 0; i < scenarioScores.length; i++){
            if(scenarioScores[i].pos < currentPosition){
                scoreArray.push(scenarioScores[i]);
            }
        }

        return scoreArray;
    };

    $scope.getScenarioPos = function(id){
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            console.log(id, scenarios[i].id);
            if(id === scenarios[i].id){
                return i;
            }
        }
        console.error('could not find scenario position');
        return -1;
    };

    $scope.init = function(){

    };

    $scope.$on('player-data-loaded',function(){
        $scope.playerData = dataService.getData('playerData');
        // console.log('player data', $scope.playerData);
        $scope.checkFilterScores();
    });

    $scope.$on('scenario-data-loaded',function(){
        $scope.scenarioData = dataService.getData('scenarioData');
        // console.log('scenario data', $scope.scenarioData);
        $scope.checkFilterScores();

    });

    $timeout($scope.init);
}]);