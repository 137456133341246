app.directive('informationCard', function($rootScope) {
  var controller = [
  '$scope',
  function(
    $scope
    ){
      $scope.buttonPressed = function(navigation){
        // console.log('information-card button pressed-> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };
  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/information-card.html',
      link: function(scope, elem, attrs) {},
      controller:controller
  };
});