app.config(function($stateProvider, $urlRouterProvider) {
  //AngularUI Router uses the concept of states
  // Learn more here: https://github.com/angular-ui/ui-router
  // Set up the various states which the app can be in.
  // Each state's controller can be found in controllers.js
  $stateProvider
  // setup an abstract state for the main view that includes tabs
    .state('game', {
      url: '/game/:levelid',
      views: {
        main: {
          templateUrl: 'templates/views/game.html'
        }
      }
    })
    .state('login', {
      url: '/login',
      views: {
        main: {
          templateUrl: 'templates/views/login.html'
        }
      }
    })
    .state('progress', {
      url: '/progress',
      views: {
        main: {
          templateUrl: 'templates/views/progress.html'
        }
      }
    })
    .state('select', {
      url: '/select',
      views: {
        main: {
          templateUrl: 'templates/views/select.html'
        }
      }
    });
  // if none of the above states are matched, use this as the fallback
  $urlRouterProvider.otherwise('/login');
});