app.directive('desicionMultiSelect', function($rootScope) {
  var controller = [
  '$scope',
  '$timeout',
  function(
    $scope,
    $timeout
    ){

      $scope.init = function(){
        $scope.selectedArray = [];
        $scope.questionAnswered = $scope.noEvent = $scope.showEvent = $scope.noFeedback = $scope.showFeedback = false;
        $scope.index = $scope.$parent.$index;
        $scope.answeredPoints = 0;

        $('.card-'+$scope.index+' .answer').removeClass('selected');
        $scope.checkPreEvent();
      };
      $scope.feedbackComplete = function(card, index){
        if($scope.checkPassRequiredFailed(card)){
          $scope.init();
        }else if(card.event){
          $scope.showFeedback = false;
          $scope.showEvent = true;
          $scope.event = card.event;
        }else {
          $scope.buttonPressed(card.navigation);
        }
      };

      $scope.checkEventOrFeedback = function(){
        var card = $scope.$parent.card;
        if(card.event || card.feedback){
          return false;
        }
        var answerNoFeedback = false;
        card.answers.forEach(function(a){
          if(a.feedback === undefined){
            answerNoFeedback = true;
          }
        }.bind(this));

        if(answerNoFeedback){
          return true;
        }else{
          return false;
        }
      };

      $scope.submitAnswer = function(card,index){
        $scope.questionAnswered = true;

        var answerIdArray = [];
        $scope.answeredPoints = 0;
        $scope.selectedArray.forEach(function(a){
          $scope.answeredPoints += a.points;
          answerIdArray.push(a.id);
        }.bind(this));
        $scope.isCorrect = $scope.answeredPoints >= card.correct;
        var passRequiredFailed = $scope.checkPassRequiredFailed(card);

        $scope.answeredPoints = (!$scope.isCorrect && card.incorrect_points !== undefined)? card.incorrect_points:$scope.answeredPoints;
        var question_time = Date.now() - $scope.qTimer;
        var answerSubmission = {
          card_id:$scope.index,
          type:'multi-select',
          question_time:question_time,
          correct:$scope.isCorrect,
          question_id:card.question_id,
          answer_id:undefined,
          answer_text:undefined,
          answer:$scope.selectedArray,
          no_bounce:card.no_bounce?true:false,
          points: card.points_override !== undefined ? card.points_override:$scope.answeredPoints
        };
        if(!passRequiredFailed){
          $scope.$emit('submit-answer', answerSubmission);
        }

        if(card.feedback || answer.feedback){
          $timeout(function(){
            $scope.showFeedback = true;
          });
          $scope.feedback = $scope.isCorrect ? card.feedback.correct : card.feedback.incorrect;
        }else if(passRequiredFailed){
          $scope.init();
        }else if(card.event){
          $scope.showEvent = true;
        }else{
          $scope.noFeedback = $scope.noEvent = true;
        }
      };

      $scope.selectAnswer = function(answer, index){
        var elem = $('.card-'+$scope.index+' .answer-'+index);
        var isSelected = !elem.hasClass('selected');
        elem.toggleClass('selected');
        if(isSelected){
          var a = _.cloneDeep(answer);
          a.id = index;
          $scope.selectedArray.push(a);
        }else{
          $scope.selectedArray = $scope.selectedArray.filter(function(a){ return a.id !== index; }.bind(this));
        }
      };

      $scope.checkPassRequiredFailed = function(card){
        if(card.required_pass !== true){
          return false;
        }else{
          return !$scope.isCorrect;
        }
      };

      $scope.checkPreEvent = function(){
        $scope.showPreEvent = $scope.$parent.card.pre_event;
      };

      $scope.preEventComplete = function(){
        $scope.showPreEvent = false;
      };

      $scope.buttonPressed = function(navigation){
        // console.log('desicion-card button pressed-> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };

      $scope.startTimer = function(){
        $scope.qTimer = Date.now();
      };

      $scope.$on('init', function (event, data) {
        if(data.index === $scope.index){
          $scope.init();
        }
      });

      $scope.$on('slide-change-end', function (event, data) {
        if(data.index === $scope.index){
          $scope.startTimer();
        }
      });

      $scope.init();
  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/desicions/desicion-multi-select.html',
      link: function(scope, elem, attrs) {
      },
      controller:controller
  };
});