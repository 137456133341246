app.directive('desicionInputBox', function($rootScope) {
  var controller = [
  '$scope',
  '$timeout',
  function(
    $scope,
    $timeout
    ){

      $scope.init = function(){
        $scope.questionAnswered = $scope.noEvent = $scope.showEvent = $scope.showPreEvent = $scope.noFeedback = $scope.showFeedback = false;
        $scope.index = $scope.$parent.$index;
        $scope.answeredPoints = 0;
        $scope.inputAnswer = undefined;

        $scope.checkPreEvent();
      };
      $scope.feedbackComplete = function(card, index){
        if($scope.checkPassRequiredFailed(card)){
          $scope.init();
        }else if(card.event){
          $scope.showFeedback = false;
          $scope.showEvent = true;
          $scope.event = card.event;
        }else {
          $scope.buttonPressed(card.navigation);
        }
      };

      $scope.checkEventOrFeedback = function(){
        var card = $scope.$parent.card;
        if(card.event || card.feedback){
          return false;
        }else{
          return true;
        }
      };

      $scope.submitAnswer = function(answer, card){
        $scope.isCorrect = (answer.correct === $scope.inputAnswer);

        $scope.questionAnswered = true;
        $scope.answeredPoints = (!$scope.isCorrect && answer.incorrect_points !== undefined)? answer.incorrect_points:answer.points;
        var question_time = Date.now() - $scope.qTimer;
        var passRequiredFailed = $scope.checkPassRequiredFailed(card);
        var answerSubmission = {
          card_id:$scope.index,
          type:'input-box',
          correct:$scope.isCorrect,
          question_time:question_time,
          question_id:card.question_id,
          answer_id:undefined,
          answer_text:undefined,
          answer:$scope.inputAnswer,
          no_bounce:card.no_bounce?true:false,
          points: card.points_override !== undefined ? card.points_override:$scope.answeredPoints
        };
        if(!passRequiredFailed){
          $scope.$emit('submit-answer', answerSubmission);
        }

        if(card.feedback || answer.feedback){
          $timeout(function(){
            $scope.showFeedback = true;
          });
          $scope.feedback = $scope.isCorrect ? card.feedback.correct : card.feedback.incorrect;
        }else if(passRequiredFailed){
          $scope.init();
        }else if(card.event){
          $scope.showEvent = true;
        }else{
          $scope.noFeedback = $scope.noEvent = true;
        }
      };

      $scope.checkPassRequiredFailed = function(card){
        if(card.required_pass !== true){
          return false;
        }else{
          return !$scope.isCorrect;
        }
      };

      $scope.checkPreEvent = function(){
        $scope.showPreEvent = $scope.$parent.card.pre_event?true:false;
      };

      $scope.preEventComplete = function(){
        $scope.showPreEvent = false;
      };

      $scope.buttonPressed = function(navigation){
        // console.log('desicion-card button pressed-> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };

      $scope.startTimer = function(){
        $scope.qTimer = Date.now();
      };

      $scope.$on('init', function (event, data) {
        if(data.index === $scope.index){
          $scope.init();
        }
      });

      $scope.$on('slide-change-end', function (event, data) {
        if(data.index === $scope.index){
          $scope.startTimer();
        }
      });

      $scope.init();
  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/desicions/desicion-input-box.html',
      link: function(scope, elem, attrs) {
      },
      controller:controller
  };
});