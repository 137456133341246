angular.module('tgaApp.controllers.game', [])
.controller('GameCtrl', [
    '$rootScope',
    '$scope',
    '$compile',
    '$timeout',
    'soundService',
    'loadingSpinnerService',
    'activityDataService',
    'dataService',
    'videoService',
    'cssAnimationService',
    'envService',
    'analyticsService',
    'scoreService',
    'userService',
    'scormService',
    'gameConfig',
    '$stateParams',
    '$state',
    'preloadService',
    'ihsGameService',
    function(
        $rootScope,
        $scope,
        $compile,
        $timeout,
        soundService,
        loadingSpinnerService,
        activityDataService,
        dataService,
        videoService,
        cssAnimationService,
        envService,
        analyticsService,
        scoreService,
        userService,
        scormService,
        gameConfig,
        $stateParams,
        $state,
        preloadService,
        ihsGameService
    ) {
    console.log('controllers/game.js up');
    $scope.lastSlideIndex = undefined;
    $scope.gameData = undefined;
    $scope.swiper = undefined;
    $scope.game_id  = undefined;
    $scope.playerPoints = 0;
    $scope.previousPoints = 0;
    $scope.playerAnswers = [];
    $scope.pointsPrefix = '';
    $scope.pointsPostfix = '';
    $scope.playerData = undefined;
    $scope.displayPoints = 1000000;


    $scope.ingestGameData = function(success, event){
        if(!success){
            $scope.gotoSelect();
            return;
        }

        $scope.gameData = event.data;
        $scope.previousPoints = $scope.playerPoints = $scope.startingPoints = $scope.gameData.starting_points;
        $scope.pointsPrefix = $scope.gameData.points_prefix;
        $scope.pointsPostfix = $scope.gameData.points_postfix;
        $scope.getLevelId();
        $scope.totalRoundTime = Date.now();
        if($scope.gameData.preloadImages) preloadService.preloadImages($scope.gameData.preloadImages);
    };

    $scope.init = function(){
        activityDataService.authenticateLocalActivityData({url:'./data/level_'+$stateParams.levelid+'.json'}, $scope.ingestGameData);
    };

    $scope.swiperInit = function(swiper){
        $scope.swiper = swiper;
        $timeout(function(){
            $('.swiper-pagination-progress').css('bottom','0');
        },250);
    };

    $scope.onSlideChangeStart = function(swiper){
        $scope.$broadcast('slide-change-start', {index:swiper.activeIndex});
        $('.gameContainer').addClass('in-transit');
    };

    $scope.onSlideChangeEnd = function(swiper){
        $scope.$broadcast('init', {index:$scope.lastSlideIndex});
        $scope.$broadcast('slide-change-end', {index:swiper.activeIndex});
        $timeout(
            function(){
                $('.gameContainer').removeClass('in-transit');
            });
    };

    $scope.nextSlide = function(){
        if( $scope.swiper.animating ||  $scope.swiper.isEnd) return;
        $scope.lastSlideIndex = $scope.swiper.activeIndex;
        $scope.swiper.slideNext();
    };

    $scope.prevSlide = function(){
        if( $scope.swiper.animating ||  $scope.swiper.isBeginning) return;
        $scope.lastSlideIndex = $scope.swiper.activeIndex;
        $scope.swiper.slidePrev();
    };
    $scope.slideTo = function(slide){
        if( $scope.swiper.animating ||  $scope.swiper.slides.length-1  < slide || slide < 0) return;
        $scope.lastSlideIndex = $scope.swiper.activeIndex;
        console.log(slide);
        $scope.swiper.slideTo(slide, 0);
    };
    // Score/Answer Related
    $scope.processScore = function(data){
        // console.log('process score');
        $scope.previousPoints = $scope.playerPoints;
        $scope.playerPoints += data.points;
        $scope.$broadcast('score-update', {points:$scope.playerPoints});
    };

    $scope.processAnswer = function(data){
        // console.log('process answer');
        $scope.playerAnswers = $scope.playerAnswers.filter( function(val){ return val.card_id !== data.card_id;}.bind(this));
        $scope.playerAnswers.push(data);
        $scope.playerAnswers.sort(function(a,b){return a.card_id - b.card_id;});
        // console.log(data);
        if(!gameConfig.useLocalData){
            var answer = {
                game_id:$scope.game_id,
                question_id:data.question_id,
                time:data.question_time,
                answer_text:data.answer_text,
                correct:data.correct,
                answer_id:data.answer_id
            };
            scoreService.reportAnswer(answer);
            // console.log(answer);
        }
        // console.log($scope.playerAnswers);
    };

    $scope.bouncePoints = function(points, correct){
        $timeout.cancel($scope.animTimeout);
        $scope.displayPoints = Math.abs(points);
        $scope.displayPointsSymbol = points >= 0 ? "+":"-";
        var elem = $('.display-points');

        elem.removeClass('correct incorrect').addClass(points>=0?'correct':'incorrect');
        var after = function(){
            $timeout.cancel($scope.animTimeout);
            $scope.animTimeout = $timeout( function(){
                cssAnimationService.attachCSSAnimation({
                    element: $('.display-points'),
                    animation: 'animated fadeOut',
                    remove:'bounceIn',
                    duration: '0.66s',
                    revert:false
                });
            },1000);
        }.bind(this);

        cssAnimationService.attachCSSAnimation({
            element: elem,
            animation: 'animated bounceIn',
            remove: 'bounceIn fadeOut animated hide-element',
            duration: '0.66s',
            after: after
        });
    };
    // Event Watchers
    $scope.$on('navigation', function (event, data) {
        // console.log(data);
        var direction = data.direction;
        if(data.direction.answer_based){
            direction = "goto:"+data.direction.destinations[data.answer_id];
        }
        if(direction.indexOf('goto') !== -1){
            var index = parseInt(direction.split(':')[1]);
            if(index === 0)$scope.playerPoints = $scope.startingPoints;
            $scope.slideTo(index);
        }else{
            switch(direction){
                case 'forward':
                    $scope.nextSlide();
                    break;
                case 'back':
                    $scope.prevSlide();
                    break;
                case 'select':
                    $scope.gotoSelect();
                    break;
                case 'next':
                    $scope.gotoNext();
                    break;
                case 'progress':
                    $scope.gotoProgress();
                    break;
            }
        }
    });

    $scope.$on('submit-answer', function (event, data) {
        // console.log('answer-submitted: ', data);
        $scope.processAnswer(data);
        $scope.processScore(data);
        if(data.no_bounce === false){
            $scope.bouncePoints(data.points, data.correct);
        }
    });

    $scope.$on('set-complete', function (event, data) {
        if($scope.gameData.final_scenario){
            $scope.playerData.completed = true;
            console.log('has completed the final scenario');
        }else{
            console.log('module-complete: unlocking ',$scope.gameData.unlocks);
        }
        $scope.playerData.current_scenario = $scope.gameData.unlocks;
        var score = {
            id:$scope.gameData.id,
            pos:$scope.gameData.pos,
            title:$scope.gameData.title,
            min_value:$scope.gameData.min_value,
            max_value:$scope.gameData.max_value,
            points:$scope.playerPoints
        };
        var scores = $scope.playerData.scenarioScores.filter(function(s){
            return s.id !== score.id && s.id !== 'demo';
        }.bind(this));
        scores.push(score);
        scores.sort(function(a, b){return a.pos - b.pos;});
        $scope.playerData.scenarioScores = scores;
        ihsGameService.setGameData($scope.playerData);
        if(!gameConfig.useLocalData){
            scoreService.reportScore({
                game_id: $scope.game_id,
                points: $scope.playerPoints,
                time: Date.now() - $scope.totalRoundTime
            }, $scope.getScore);
        }
        if(scormService.isConnected()){
            $scope.updateScormProgress();
        }
        console.log('data saved: ',$scope.playerData);
    });

    $scope.updateScormProgress = function(){
        if($scope.playerData.completed){
            console.log('have completed last scenario, sending SCORM complete');
            scormService.setScore(100);
            scormService.setCompletion(true);
        }else{
            var scenarioScores = $scope.playerData.scenarioScores;
            var currentPosition = $scope.getScenarioPos($scope.playerData.current_scenario);
            var totalScenarios = $scope.scenarioData.scenarios.length;
            var completedScenarios = 0;
            for(var i = 0; i < scenarioScores.length; i++){
                if(scenarioScores[i].pos < currentPosition){
                    completedScenarios++;
                }
            }
            var completedPercentage = (completedScenarios/totalScenarios) * 100;
            console.log('setting SCORM completion and score at %'+completedPercentage);
            scormService.setScore(completePercentage);
            scormService.setCompletion(false);
        }


    };

    $scope.getScenarioPos = function(id){
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            console.log(id, scenarios[i].id);
            if(id === scenarios[i].id){
                return i;
            }
        }
        console.error('could not find scenario position');
        return -1;
    };

    $scope.getLevelId = function(){
        if($scope.gameData && $scope.playerData && !gameConfig.useLocalData && !$scope.game_id){
            ihsGameService.getLevelData({
                slug:$scope.gameData.id
            }, $scope.processLevelId);
        }
    };

    $scope.processLevelId = function(success, data){
        // console.log('slug:'+data.data.slug+' , game_id:'+data.data.game_id);
        $scope.game_id = data.data.game_id;
        userService.startSession({game_id:$scope.game_id },function(){});
    };

    $scope.checkLevelProgress = function(){
        if($scope.playerData && $scope.scenarioData){
            if(gameConfig.enforceProperLevel){
                if($scope.playerData.completed){
                    $scope.gotoSelect();
                    return;
                }
                if($scope.playerData.current_scenario !== $stateParams.levelid){
                    $state.go('game', {levelid: $scope.playerData.current_scenario});
                    return;
                }
            }
            $scope.getLevelId();
        }
    };

    $scope.gotoSelect = function(){
        $state.go('select');
    };

    $scope.gotoNext = function(){
        $state.go('game', {levelid: $scope.gameData.unlocks});
    };

    $scope.gotoProgress = function(){
        $state.go('progress');
    };

    $scope.$on('player-data-loaded',function(){
        $scope.playerData = dataService.getData('playerData');
        // console.log('player data', $scope.playerData);
        $scope.checkLevelProgress();
    });
    $scope.$on('scenario-data-loaded',function(){
        $scope.scenarioData = dataService.getData('scenarioData');
        // console.log('scenario data', $scope.scenarioData);
        $scope.checkLevelProgress();
    });

    $timeout($scope.init);
}]);
