angular.module('tgaApp.services.sound-service', [])
.service('soundService', function (
        $timeout
    ) {
    this.sounds = [];
    this.pauseArray = [];

    this.play = function(id, settings){
        if(Howler._muted) return;
        var s = settings || {};
        var i;
        var service = this;
        for(i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].id === id){
                if(s.loop)   this.sounds[i].howl.loop = true;
                if(s.volume) this.sounds[i].howl.volume = s.volume;
                if(s.onComplete) this.sounds[i].howl.once('end', s.onComplete);
                if(s.delay){
                    $timeout(function(){
                        service.sounds[i].howl.play();
                    },settings.delay);
                }else{
                    this.sounds[i].howl.play();
                }

                break;
            }
        }
    };

    this.pause = function(){
        var i;
        this.pauseArray = [];
        for(i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].howl.playing()){
                this.sounds[i].howl.pause();
                this.pauseArray.push(this.sounds[i].id);
            }
        }
    };

    this.resume = function(){
        var i;
        for(i = 0; i < this.sounds.length; i++){
            if(this.pauseArray.indexOf(this.sounds[i].id) !== -1){
                this.sounds[i].howl.play();
            }
        }
        this.pauseArray = [];
    };

    this.muteAll = function(mute){
        Howler.mute(mute);
    };

    this.muteMultiple = function(arr, mute){
        var i;
        for(i = 0; i < arr.length; i++){
            this.mute(arr[i], mute);
        }
    };

    this.mute = function(id, mute){
        var i;
        for(i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].id === id){
                // console.log(this.sounds[i]);
                this.sounds[i].howl.mute(mute);
            }
        }
    };

    this.load = function(options){
        var sound = new Howl({
            src: options.src,
            autoplay:options.autoplay || false,
            volume: options.volume || 1.0,
            loop: options.loop || false
        });

        this.sounds.push({
            id:options.id,
            howl:sound
        });
        // console.log(this.sounds);
    };

    this.loadMultiple = function(data){
        var i, sound;
        for(i = 0; i < data.length; i++){
            this.load(data[i]);
        }
    };

    this.unload = function(id){
        var i, exists;
        for(i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].id === id){
                exists = true;
                this.sounds[i].howl.unload();
                this.sounds[i].id = null;
                break;
            }
        }
        if(exists){
            this.sounds.splice(i, 1);
            this.pauseArray.splice(this.pauseArray.indexOf(id), 1);
        }
    };

    this.unloadAll = function(){
        Howler.unload();
        this.sounds = [];
    };

    this.reset = function(){
        this.unloadAll();
        this.pauseArray = [];
    };

    this.setVolume = function(id, volume){
        for(var i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].id === id){
                this.sounds[i].howl.volume(volume);
                break;
            }
        }
    };

    this.getVolume = function(id){
        for(var i = 0; i < this.sounds.length; i++){
            if(this.sounds[i].id === id){
                return this.sounds[i].howl.volume();
            }
        }
        return undefined;
    };

    this.checkLoadCompletion = function(){
        if(this.sounds.length < 1) return -1;
        var percentage, i, loaded = 0;
        for(i = 0 ; i < this.sounds.length; i++){
            if(this.sounds[i].howl.state() === 'loaded'){
                loaded++;
            }
        }
        return loaded/this.sounds.length;
    };


});