app.directive('loadingIndicator', function($rootScope) {
  return {
      restrict:'E',
      templateUrl:'templates/directives/loading-indicator.html',
      link: function(scope, elem, attrs) {
        scope.isLoading = false;
        $rootScope.$on('loadingStart', function() {
          scope.isLoading = true;
        });
        $rootScope.$on('loadingEnd', function() {
          scope.isLoading = false;
        });
      }
  };
});