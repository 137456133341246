angular.module('tgaApp.services.ihs-game-service', [])
.service('ihsGameService', function (
    $rootScope,
    dataService,
    activityDataService,
    gameConfig,
    userService,
    domainService
) {
    this.defaultDummyData = {
        current_scenario:'finance',
        scenarioScores:[],
        completed:false
    };
    //model for scenario scores
    // {
    //     id:'finance',
    //     title:"Finance",
    //     min_value:0,
    //     max_value:100,
    //     points:50
    // }

    this.resetData = function(){
        dataService.removeData('playerData');
        dataService.setLocalStorageData('localPlayerData', this.defaultDummyData);
        this.resetDataFlag = true;
    };

    this.ingestScenarioData = function(success, event){
        dataService.setData('scenarioData', event.data);
        $rootScope.$broadcast('scenario-data-loaded');
    };

    this.setGameData = function(data){
        dataService.setData('playerData', data);
        if(gameConfig.useLocalData){
            dataService.setLocalStorageData('localPlayerData', data);
        }else{
            activityDataService.setPlayerData(data);
        }

    };

    this.getGameData = function(){
        if(dataService.getData('playerData') && dataService.getData('scenarioData')){
            $rootScope.$broadcast('player-data-loaded');
            $rootScope.$broadcast('scenario-data-loaded');
            return;
        }

        if(gameConfig.useLocalData){
            if(!dataService.getLocalStorageData('localPlayerData') || gameConfig.forceDummyData){
                dataService.setLocalStorageData('localPlayerData', this.defaultDummyData);
            }
            dataService.setData('playerData', dataService.getLocalStorageData('localPlayerData'));
            $rootScope.$broadcast('player-data-loaded');
            activityDataService.authenticateLocalActivityData({url:'./data/scenarios.json'}, this.ingestScenarioData.bind(this));
        }else{
            userService.register({
                display_name:$rootScope.username,
                username:$rootScope.id
            }, this.registerCallback.bind(this));
        }
    };

    this.registerCallback = function(data){
        activityDataService.getPlayerData(this.ingestPlayerData.bind(this));
    };

    this.ingestPlayerData = function(success, data){
        var pData;
        if(!data.data.data[gameConfig.playerDataNameSpace] || gameConfig.forceDummyData || this.resetDataFlag){
            activityDataService.setPlayerData(this.defaultDummyData);
            pData = this.defaultDummyData;
            this.resetDataFlag = false;
            console.log('using dummy data:',pData);
        }else{
            pData = data.data.data[gameConfig.playerDataNameSpace];
            console.log('found player data:',pData);
        }
        dataService.setData('playerData',pData);
        activityDataService.authenticateLocalActivityData({url:'./data/scenarios.json'}, this.ingestScenarioData.bind(this));
        $rootScope.$broadcast('player-data-loaded');
    };

    this.getLevelData = function(settings, callback){
        activityDataService.authenticateActivityData({
            slug:settings.slug,
            domain:domainService.domain
        }, callback);
    };



});