angular.module('tgaApp.services.score-service', [])
.service('scoreService', function (
    $http,
    dataService,
    userService,
    envService
) {

    this.serverAddress = envService.read('serverAddress');
    // Get score
    this.getScore = function(params, callback){
        $http({
            method: 'GET',
            url: this.serverAddress + 'player/score',
            params: params
        }).then(
            function (response) {
                if(callback){
                    callback(response);
                }
            },
            function (error) {
                if(callback){
                    callback(error);
                }
            }
        );
    };

    // Report score
    this.reportScore = function(data, callback){
        $http({
            method: 'POST',
            url: this.serverAddress + 'player/score',
            data: data
        }).then(
            function (response) {
                if(callback){
                   callback(response);
                }
            },
            function (error) {
                // Store info locally
                data.user = userService.getUserData();
                dataService.setData(Date.now(), data, true);
                if(callback){
                    callback(error);
                }
            }
        );
    };

    // Report answer attempt
    this.reportAnswer = function(data, callback) {
        $http({
            method: 'POST',
            url: this.serverAddress + 'player/answer',
            data: data
        }).then(
            function (response) {
                if(callback){
                   callback(response);
                }
            },
            function (error) {
                // Store info locally
                data.user = userService.getUserData();
                dataService.setData(Date.now(), data, true);
                if(callback){
                   callback(error);
                }
            }
        );
    };

    //Get leaderboard
    this.getLeaderboard = function(params, callback) {
        $http({
            method: 'GET',
            url: this.serverAddress + 'stats/leaderboard',
            params: params
        }).then(
            function (response) {
                if(callback){
                    callback(response);
                }
            },
            function (error) {
                if(callback){
                    callback(error);
                }
            }
        );
    };

});