angular.module('tgaApp.services.loading-spinner-service', [])
.service('loadingSpinnerService', function (
    $rootScope
) {
    this.spinnerVisible = false;

    this.startSpinner = function(settings){
        $rootScope.$broadcast('loadingStart');
        this.spinnerVisible = true;
    };

    this.endSpinner = function(settings){
        $rootScope.$broadcast('loadingEnd');
        this.spinnerVisible = false;
    };

});