angular.module('tgaApp.controllers.login', [])
.controller('LoginCtrl', [
    '$rootScope',
    '$scope',
    '$compile',
    '$timeout',
    'soundService',
    'loadingSpinnerService',
    'activityDataService',
    'dataService',
    'videoService',
    'cssAnimationService',
    'envService',
    'analyticsService',
    'scoreService',
    'userService',
    'scormService',
    'gameConfig',
    '$stateParams',
    '$state',
    function(
        $rootScope,
        $scope,
        $compile,
        $timeout,
        soundService,
        loadingSpinnerService,
        activityDataService,
        dataService,
        videoService,
        cssAnimationService,
        envService,
        analyticsService,
        scoreService,
        userService,
        scormService,
        gameConfig,
        $stateParams,
        $state
    ) {
    console.log('controllers/login.js up');

    $scope.login = function(){
        $state.go('select');
    };

    $scope.$on('player-data-loaded',function(){
        $scope.playerData = dataService.getData('playerData');
        // console.log('player data', $scope.playerData);
    });

    $scope.$on('scenario-data-loaded',function(){
        $scope.scenarioData = dataService.getData('scenarioData');
        // console.log('scenario data', $scope.scenarioData);
    });

    $timeout($scope.init);
}]);