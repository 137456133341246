angular.module('tgaApp.services.video-service', [])
.service('videoService', function(
    $document
) {
    this.videoElement = null;
    this.init = function(id) {
        this.videoElement = $(id);
    };
    this.play = function(filename) {
        if(this.videoElement === null) return;
        this.videoElement.src = filename;
        this.videoElement.play();
    };
    this.resume = function() {
        if(this.videoElement === null) return;
        this.videoElement.play();
    };
    this.pause = function() {
        if(this.videoElement === null) return;
        this.videoElement.pause();
    };
    this.stop = function() {
        if(this.videoElement === null) return;
        this.videoElement.pause();
        this.videoElement.src = videoElement.currentSrc; /** http://stackoverflow.com/a/16978083/1015046 **/
    };
    this.on = function(event, callback){
        if(this.videoElement === null) return;
        this.videoElement.on(event, callback);
    };
    this.clear = function(){
        this.videoElement = null;
    };
});