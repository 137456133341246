angular.module('tgaApp.services.data-service', [])
.service('dataService', function (
    $rootScope,
    localStorageService,
    envService
) {
    // service wrapper for localDataStorage angular plugin
    this.namespace = 'appDataStorage';
    this.data = {};
    this.initPrefix = function(namespace){
        this.namespace = namespace;
        this.data[this.namespace] = {};
    };

    // get data object from prefixed namespace in rootScope
    this.getDataObject = function(){
        return this.data[this.namespace];
    };
    // set the data with key value pair in local, if localData bool set to true will save to localStorage
    this.setData = function(key, data, localData){
        var appData = this.data[this.namespace];
        appData[key] = data;
        $rootScope[this.namespace] = this.data[this.namespace] = appData;
        if(localData){
            this.setLocalStorageData(key,data);
        }
    };

    // get value from prefixed data object
    this.getData = function(key){
        var appData = this.data[this.namespace];
        return appData[key] || this.getLocalStorageData(key);
    };

    // remove data
    this.removeData = function(key) {
        var appData = this.data[this.namespace];
        if(appData && appData[key]) delete appData[key];
        localStorageService.remove(key);
    };

    // set data in local storage
    this.setLocalStorageData = function(key,data){
        localStorageService.set(key, data);
    };

    // get data from local storage
    this.getLocalStorageData = function(key){
        return localStorageService.get(key);
    };

    // set a cookie with key value pair and number of days to keep (defaults to 1)
    this.setCookieData = function(key, val, days){
        days = days || 1;
        localStorageService.cookie.set(key, val, days);
    };

    // get cookie data with key value pair
    this.getCookieData = function(key){
        return localStorageService.cookie.get(key);
    };

    // erase all data in app
    this.clearData = function(){
        localStorageService.clearAll();
        localStorageService.cookie.clearAll();
    };

});