angular.module('tgaApp.services.settings-service', [])
.service('settingsConfigService', function (
    $rootScope,
    dataService
) {
    this.defaultSettings = {
        sound:false,
        developer:false,
        developerOverrideRouting:false,
        developerDebugLogin: false,
        developerActivityMode: false
    };

    // changes the user settings
    this.initializeSettings = function (){
        var settings = dataService.getLocalStorageData('appSettings');
        if(!settings){
            settings = this.defaultSettings;
            dataService.setData('appSettings', this.defaultSettings, true);
        }else{
            dataService.setData('appSettings', settings);
        }
        this.checkDevMode();
        return settings;
    };

    this.refreshSettings = function(settings){
        dataService.setData('appSettings', settings, true);
        this.checkDevMode();
    };

    // this makes sure the body has a dev-mode tag if the styling needs to change
    this.checkDevMode = function(){
        var settings = dataService.getData('appSettings');
        if(settings.developer){
            $('body').addClass('dev-mode');
        }else{
            $('body').removeClass('dev-mode');
        }
    };

});