app.directive('introCard', function($rootScope) {
  var controller = [
  '$scope',
  function(
    $scope
    ){
      $scope.buttonPressed = function(navigation){
        // console.log('intro-card button pressed -> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };
  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/intro-card.html',
      link: function(scope, elem, attrs) {},
      controller:controller
  };
});