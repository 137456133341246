app.directive('completeCard', function($rootScope) {
  var controller = [
  '$scope',
  '$filter',
  'utilityService',
  function(
    $scope,
    $filter,
    utilityService
    ){

      $scope.index = $scope.$parent.$index;

      $scope.processConditionText = function(str){
        var matches =
          (str.match(/\[.+?\]/g)   // Use regex to get matches
            || []                  // Use empty array if there are no matches
          ).map(function(s) {    // Iterate matches
            return s.slice(1,-1); // Remove the brackets
          });

        var rArray = [];
        matches.forEach(function(m){
          var r;
          if(m.indexOf('*') !== -1){
            r = new Number(m.replace(/ /g,'').split("*")[1])*$scope.playerPoints;
          }else{
            r = $scope.playerPoints;
          }
          rArray.push({match:'['+m+']',replace: $filter('number')(r)});
        }.bind(this));

        var strCpy = _.cloneDeep(str);
        for(var i = 0; i < rArray.length; i++){
          strCpy = strCpy.replace(rArray[i].match,rArray[i].replace);
        }
        // console.log(strCpy);
        return strCpy;
      };

      $scope.checkConditions = function(condition, points){
        if(condition.exception && condition.exception <= points ) {
          return false;
        }
        if(condition.type === "above" && points > condition.value){
          return true;
        }
        if(condition.type === "below" && points < condition.value){
          return true;
        }
        if(condition.type === "at_above" && points >= condition.value){
          return true;
        }
        if(condition.type === "at_below" && points <= condition.value){
          return true;
        }
        return false;
      };

      $scope.getProgressPercentage = function(data, points){
        var val = Math.round(utilityService.convertRange(points, [data.min_value, data.max_value], [0, 100]));
        if(val < 0) val = 0;
        if(val > 100) val = 100;
        return utilityService.convertRange(points, [data.min_value, data.max_value], [0, 100]);
      };

      $scope.getProgressValueString = function(data, points){
        var str = $filter('number')(points) + '/' + $filter('number')(data.max_value);
        return str;
      };

      $scope.buttonPressed = function(navigation){
        // console.log('intro-card button pressed -> navigation: '+navigation);
        $scope.$emit('navigation', {direction:navigation});
      };

      $scope.$on('slide-change-start', function (event, data) {
        if(data.index === $scope.index){
          $scope.$emit('set-complete');
        }
      });

  }];
  return {
      restrict:'E',
      templateUrl:'templates/directives/cards/complete-card.html',
      link: function(scope, elem, attrs) {},
      controller:controller
  };
});