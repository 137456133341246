angular.module('tgaApp.services.auth-service', [])
.service('authService', function (
    dataService
) {

    this.storageKey = 'authentication';

    this.authentication = {
        isAuth: false,
        token: '',
        userId: ''
    };

    this.getAuthentication = function() {
        // Check cache
        if (this.authentication && this.authentication.isAuth) {
            return this.authentication;
        }
        this.authentication = dataService.getData(this.storageKey);
        if (!this.authentication || !this.authentication.isAuth) {
            this.clearAuthentication();
        }
        return this.authentication;
    };

    this.setAuthentication = function(auth, remember){
        this.authentication = auth;
        dataService.setData(this.storageKey, this.authentication, remember);
    };

    this.clearAuthentication = function() {
        this.authentication = {
            isAuth: false,
            token: '',
            userId: ''
        };
        dataService.removeData(this.storageKey);
        return this.authentication;
    };
});