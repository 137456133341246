angular.module('tgaApp.services.css-animation-service', [])
.service('cssAnimationService', function(
    $timeout
) {
    this.attachCSSAnimation = function(settings){
        settings.element.css("-webkit-animation-duration", settings.duration).css("-moz-animation-duration", settings.duration).css("animation-duration", settings.duration);
        settings.element.one(
            'webkitAnimationEnd oanimationend msAnimationEnd animationend',
            function(e) {
                if(settings.revert){
                    settings.element.removeClass(settings.animation);
                }
                if(settings.after){
                    settings.after();
                }

            }
        );

        if(settings.remove)settings.element.removeClass(settings.remove);
        settings.element.addClass(settings.animation);
    };
});