angular.module('tgaApp.services.analytics-service', [])
.service( 'analyticsService', function (
) {
    this.sendPageLoad = function(){
      if(!window.ga || window.ga === undefined)return;
      // Feature detects Navigation Timing API support.
      if (window.performance) {
        // Gets the number of milliseconds since page load
        // (and rounds the result since the value must be an integer).
        var timeSincePageLoad = Math.round(performance.now());
        // Sends the timing hit to Google Analytics.
        ga('send', 'timing', 'Dependencies', 'load', timeSincePageLoad);
        console.log('page load analytics fired');
      }
    };

    this.sendEvent = function(data){
      if(!window.ga || window.ga === undefined)return;
      var eventDetails = {
          hitType: 'event',
          eventCategory: data.category,
          eventAction: data.action
      };

      if(data.label) eventDetails.eventLabel = data.label;
      if(data.value) eventDetails.eventValue = data.value;

      ga('send', eventDetails);
      console.log('analytics event fired');
      console.log(data);
    };
});