angular.module('tgaApp.services.domain-service',[])
    .service('domainService', ['$http', '$location', 'envService',
        function ($http, $location, envService) {

            var serverAddress = envService.read('serverAddress');
            var host = $location.host();
            var base = host;
            var domain = 'ihs';

            if (host.indexOf('.') >= 2) {
                var parts = host.split('.');
                domain = parts.shift();
                base = parts.join('.');
            }
            return ({
                base: base,
                domain: domain,
                checkDomain: checkDomain
            });

            function checkDomain(domain) {
                return $http({
                    method: 'POST',
                    url: serverAddress + 'domain/check',
                    data: {
                        domain: domain
                    }
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

        }]);