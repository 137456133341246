angular.module('tgaApp.controllers.select', [])
.controller('SelectCtrl', [
    '$rootScope',
    '$scope',
    '$compile',
    '$timeout',
    'soundService',
    'loadingSpinnerService',
    'activityDataService',
    'dataService',
    'videoService',
    'cssAnimationService',
    'envService',
    'analyticsService',
    'scoreService',
    'userService',
    'scormService',
    'gameConfig',
    '$stateParams',
    '$state',
    'ihsGameService',
    function(
        $rootScope,
        $scope,
        $compile,
        $timeout,
        soundService,
        loadingSpinnerService,
        activityDataService,
        dataService,
        videoService,
        cssAnimationService,
        envService,
        analyticsService,
        scoreService,
        userService,
        scormService,
        gameConfig,
        $stateParams,
        $state,
        ihsGameService
    ) {
    console.log('controllers/select.js up');

    $scope.slide = function(dir,speed){
        if(dir === 'next'){
            $scope.swiper.slideNext(speed);
        }else{
            $scope.swiper.slidePrev(speed);
        }
    };

    $scope.swiperInit = function(swiper){
        $scope.swiper = swiper;
    };

    $scope.onSlideChangeStart = function(swiper){
    };

    $scope.onSlideChangeEnd = function(swiper){
    };

    $scope.resetGame = function(){
        if (confirm('Are you sure you wish to reset your progress? You will start back at the very beginning of the game.')) {
            $scope.playerData.completed = false;
            $scope.playerData.current_scenario = 'finance';
            $scope.playerData.scenarioScores = [];
            ihsGameService.setGameData($scope.playerData);
            $state.go('game',{levelid:'finance'});
        }
    };

    $scope.logout = function(){
        $state.go('login');
    };

    $scope.gotoProgress = function(){
        $state.go('progress');
    };

    $scope.selectGame = function(game){
        $state.go('game',{levelid:game.id});
    };

    $scope.getInitialPosition = function(){
        if(!$scope.playerData)return;
        var current_scenario = $scope.playerData.current_scenario;
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            if(scenarios[i].id === current_scenario){
                // console.log(i);
                return i < 2 ? 0:3;
            }
        }
        return 0;
    };

    $scope.checkScenarioCompletion = function(id){
        if($scope.playerData.completed)return true;

        var i_index;
        var c_index;
        var scenarios = $scope.scenarioData.scenarios;
        for(var i = 0; i < scenarios.length; i++){
            if(scenarios[i].id === $scope.playerData.current_scenario){
                c_index = i;
            }else if(scenarios[i].id === id){
                i_index = i;
            }
        }
        return i_index < c_index;
    };

    $scope.init = function(){
        // loadingSpinnerService.startSpinner();
    };

    $scope.$on('player-data-loaded',function(){
        $scope.playerData = dataService.getData('playerData');
        // console.log('player data', $scope.playerData);
        $scope.checkData();
    });

    $scope.$on('scenario-data-loaded',function(){
        $scope.scenarioData = dataService.getData('scenarioData');
        // console.log('scenario data', $scope.scenarioData);
        $scope.checkData();
    });

    $scope.checkData = function(){
        if($scope.playerData && $scope.scenarioData){
            // console.log($scope.scenarioData, $scope.playerData);
            $scope.initialIndex = $scope.getInitialPosition();
        }
    };
    $timeout($scope.init);
}]);