angular.module('tgaApp.services.activity-data-service', [])
.service('activityDataService', function (
    $http,
    $rootScope,
    localStorageService,
    dataService,
    envService,
    $timeout,
    gameConfig
) {

    // get activity data from server based on credentials, needs to hook into callback
    // NOTE: currently this is NOT setup to work properly
    this.authenticateActivityData = function(settings, callback){
        // console.log('getting course data...');
        var serverAddress = envService.read('serverAddress');
        var requestString = settings.slug ? serverAddress+'game?domain='+settings.domain+'&slug='+settings.slug: serverAddress + 'game/'+settings.game_id+'?domain='+settings.domain;
        $http({
            method:'GET',
            url:requestString
        }).then(
            function success(response) {
                // console.log(response);
                callback(true, response);
            },
            function error(response) {
                // console.log(response);
                callback(false, response);
            }
        );
    };

    // get data json associated with key
    this.authenticateLocalActivityData = function(settings, callback){
        // console.log('using local activity data');
        if($rootScope.language && $rootScope.language !== 'en') settings.key = $rootScope.language+'_'+settings.key;
        var service = this;
        var selection = settings.key+'.json';
        $http({
            method:'GET',
            url:settings.url || './data/activities/'+selection
        }).then(
            function success(response) {
                // console.log(response);
                callback(true, response);
            },
            function error(response) {
                // console.log(response);
                callback(false, response);
            }
        );
    };

    this.getPlayerData = function(callback){
        // console.log('getting player data...');
        var serverAddress = envService.read('serverAddress');
        var requestString = serverAddress + 'player/data';
        $http({
            method:'GET',
            url:requestString
        }).then(
            function success(response) {
                // console.log(response);
                callback(true, response);
            },
            function error(response) {
                console.log(response);
                callback(false, response);
            }
        );
    };

    this.setPlayerData = function(data, callback){

        var d = {'data':{}};
        d.data[gameConfig.playerDataNameSpace] = data;
        var serverAddress = envService.read('serverAddress');
        var requestString = serverAddress + 'player/data';
        $http({
            method:'POST',
            url:requestString,
            data: d
        }).then(
            function success(response) {
                // console.log(response);
                if(callback)callback(true, response);
            },
            function error(response) {
                console.log(response);
                if(callback)callback(false, response);
            }
        );
    };
});