angular.module('tgaApp.services.scorm-service', [])
.service('scormService', function (
    $interval,
    $timeout
) {
    // Service for SCORM Wrapper Library: https://github.com/pipwerks/scorm-api-wrapper

    this.scorm = undefined;

    this.saved = {
        score: undefined,
        lesson_status: undefined,
        lmsConnected: undefined,
        user: {
            name:'scorm_name',
            id:'scorm_id'
        },
        version:undefined
    };

    this.heartbeatDelay = 1500000;

    // initialize SCORM connection
    this.init = function(){
        console.log('scorm-service init');

        window.addEventListener('message', function(event) {
            // console.log(event.origin, event.currentTarget.location.origin);
            var data = event.data;
            if(!data.type || data.type !== 'scorm' || event.origin === event.currentTarget.location.origin )return;
            console.log('message received from SCORM: ', event);
            this.saved.version = data.version;
            switch(data.method){
                case 'get':
                    this.getHandler(data);
                    break;
                case 'set':
                    console.log('set: '+data.model + ": success: " + data.success);
                    this.setHandler(data);
                    break;
                case 'version':
                    this.versionCallback();
                    break;
            }
        }.bind(this), false);

        this.getVersion();
    };

    this.versionCallback = function(){
        this.getScore();
        this.getLessonStatus();
        this.getEntryStatus();
        this.getUser();
        this.startHeartbeat();
    };

    this.getHandler = function(data){
        if(!this.saved.lmsConnected){
            this.saved.lmsConnected = true;
        }
        switch(data.model){
            case 'cmi.completion_status':
            case 'cmi.core.lesson_status':
                this.lessonStatusCallback(data);
                break;
            case 'cmi.score.raw':
            case 'cmi.core.score.raw':
                this.getScoreCallback(data);
                break;
            case 'cmi.learner_id':
            case 'cmi.core.student_id':
                this.saved.user.id = data.value;
                break;
            case 'cmi.learner_name':
            case 'cmi.core.student_name':
                this.saved.user.name = data.value;
                break;
            case 'cmi.entry':
            case 'cmi.core.entry':
                this.entryStatusCallback(data);
                break;
        }
    };

    this.isConnected = function(){
        return this.saved.lmsConnected;
    };

    this.provideSavedScore = function(){
        return this.saved.score;
    };

    this.provideLessonStatus = function(){
        return this.saved.lesson_status;
    };

    this.provideUser = function(){
        return this.saved.user;
    };

    this.setHandler = function(data){
        if(data.success){
            switch(data.model){
                case 'cmi.completion_status':
                case 'cmi.core.lesson_status':
                    this.saved.lesson_status = data.value;
                    break;
                case 'cmi.score.raw':
                case 'cmi.core.score.raw':
                    this.saved.score = data.value;
                    break;
            }
        }
    };

    this.initScores = function(){
        if(this.saved.version === '2004'){
            this.set("cmi.score.min", 0);
            this.set("cmi.score.max", 100);
        }else{
            this.set("cmi.core.score.min", 0);
            this.set("cmi.core.score.max", 100);
        }

    };

    this.setScore = function(number){
        if(this.saved.version === '2004'){
            this.set("cmi.score.raw", number);
            this.set("cmi.score.scaled", number/100);
        }else{
            this.set("cmi.core.score.raw", number);
        }

    };

    this.getScore = function(){
        if(this.saved.version === '2004'){
            this.get("cmi.score.raw");
        }else{
            this.get("cmi.core.score.raw");
        }

    };

    this.getScoreCallback = function(data){
        if(data.value === '') {
            // this.setScore(0);
            data.value = 0;
        }
        this.saved.score = data.value;
    };

    this.getLessonStatus = function(){
        if(this.saved.version === '2004'){
            this.get("cmi.completion_status");
        }else{
            this.get("cmi.core.lesson_status");
        }
    };

    this.lessonStatusCallback = function(data){
        var status = data.value;
        this.lesson_status = status;
        console.log(status);
    };

    this.getEntryStatus = function(){
        if(this.saved.version === '2004'){
            this.get("cmi.entry");
        }else{
            this.get("cmi.core.entry");
        }
    };

    this.entryStatusCallback = function(data){
        var status = data.value;
        console.log(status);
        if(status === "ab_initio" || status === "ab-initio"){
            console.log('first time, initialize scores');
            this.initScores();
            this.setCompletion(false);
        }
    };

    this.getUser = function(){
        if(this.saved.version === '2004'){
            this.get("cmi.learner_name");
            this.get("cmi.learner_id");
        }else{
            this.get("cmi.core.student_name");
            this.get("cmi.core.student_id");
        }
    };


    this.checkCompletion = function(){
        return this.saved.lesson_status === "completed";
    };

    this.setCompletion = function(complete){
        var c_str = complete ? "completed":"incomplete";
        if(this.saved.version === '2004'){
            this.set("cmi.completion_status", c_str);
            if(complete){
                this.set("cmi.success_status", "passed");
            }
        }else{
            this.set("cmi.core.lesson_status", c_str);
        }
    };

    this.startHeartbeat = function(){
        var func = function(){
            var msg = {
                type:'scorm',
                method:'heartbeat'
            };
            parent.postMessage(msg, '*');
        }.bind(this);
        this.heartbeatInterval = $interval(func, this.heartbeatDelay);
    };

    this.stopHeartbeat = function(){
        if(this.heartbeatInterval){
            $interval.cancel(this.heartbeatInterval);
        }
    };

    this.getVersion = function(){
        var msg = {
            type:'scorm',
            method:'version'
        };
        parent.postMessage(msg, '*');
    };

    this.get = function(model){
        var msg = {
            type:'scorm',
            method:'get',
            model:model
        };
        parent.postMessage(msg, "*");
    };

    this.set = function(model, value){
        var msg = {
            type:'scorm',
            method:'set',
            model:model,
            value: value
        };
        parent.postMessage(msg, "*");
    };
});