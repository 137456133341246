app.constant('gameConfig', {
    dimensions:{
        width:1920,
        height:1080
    },
    playerDataNameSpace:'ihs',
    useLocalData:false,
    forceDummyData:false,
    enforceProperLevel:true,
    allowReset:true
});