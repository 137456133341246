app.config(function(envServiceProvider) {
    // set the domains and variables for each environment
    envServiceProvider.config({
        domains: {
            development: ['localhost','ihs.ihs.local'],
            production: ['thegameagencyportal.com','bemorefun.com','trainingarcade.com']
            // anotherStage: ['domain1', 'domain2'],
            // anotherStage: ['domain1', 'domain2']
        },
        vars: {
            development: {
                enableDevSettings:true,
                serverAddress:'http://backend.ihs.local/api/v5/',
                appAddress:'http://localhost:3000/',

            },
            production: {
                enableDevSettings:false,
                serverAddress:'https://ihs-backend.thegameagencyportal.com/api/v5/',
                appAddress:'https://ihs.thegameagencyportal.com/',
            },
            defaults: {
                gameId:1
            }
        }
    });

    // run the environment check, so the comprobation is made
    // before controllers and services are built
    envServiceProvider.check();
    // force set development toggle
    // envServiceProvider.set('development');
});